import { ArrowRight } from "@/assets/icons/24/arrow/ArrowRight";
import { Spark } from "@/assets/icons/Logo";
import { cn } from "@/common/utils/css.utils";
import { useCreateAdNavigator } from "@/features/create-ad/hooks/useCreateAdNavigator";
import { Button } from "@/ui/cva/Button";
import { Heading } from "@/ui/cva/Heading";
import { useTranslation } from "next-i18next";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import Image from "@/components/common/Image";
import BackgroundImage from "@/components/Homepage/assets/hero-block-bg.jpg";

type HeroBlockProps = {
  className?: string;
};

export const HomepageHeroBlock = ({ className }: HeroBlockProps) => {
  const { t } = useTranslation();
  const { gotoWizard: gotoCreateAdWizard } = useCreateAdNavigator();

  const handleSellNowClick = () => {
    /** Trigger `hp_sell_now` event. */
    analyticsService.trackHpSellNow();
    gotoCreateAdWizard();
  };

  return (
    <section
      className={cn(
        "relative flex justify-center overflow-hidden bg-brand-500 px-6 pb-10 pt-6 md:min-h-[28rem] md:px-40 md:pb-6",
        className
      )}
    >
      <Image
        src={BackgroundImage}
        layout="fill"
        objectFit="cover"
        objectPosition="right"
        alt="Background"
        className="hidden md:block"
      />
      <div className="flex flex-col items-center justify-center space-y-4 md:space-y-8">
        <div className="z-10 flex flex-col items-center space-y-6">
          <div className="flex flex-col items-center">
            <Spark className="size-20 text-attention-500" />
            <Heading as="h1" className="text-center text-white">
              {t("homepage:homepage-hero-block-title-v3")}
            </Heading>
          </div>
          <Button
            color="attention"
            size="large"
            rightIcon={<ArrowRight className="text-white" />}
            onClick={handleSellNowClick}
          >
            {t("homepage:homepage-create-ad-block-start-button")}
          </Button>
        </div>
      </div>
    </section>
  );
};
