import { useLanguage } from "@/common/hooks/pages.hooks";
import useSearchProducts from "@/Apollo/hooks/useSearchProducts";
import { SearchProductsByQueryItem } from "@/common/contracts/home.contracts";
import { useMasterFilter } from "@/features/master-filter/components/MasterFilterProvider";

const NO_PRODUCTS: SearchProductsByQueryItem[] = [];

export const useHomepageForYouProducts = () => {
  const { language } = useLanguage();
  const { filter, updateSearchQueryVariables } = useMasterFilter();

  const variables = updateSearchQueryVariables({
    input: {
      recommended: true,
      pagination: { limit: 8 },
    },
    lang: language,
  });

  const { data } = useSearchProducts({
    variables,
    skip: !filter,
  });

  const products = data?.searchProducts?.items ?? NO_PRODUCTS;
  const searchMeta = {
    queryId: data?.searchProducts?.queryID ?? undefined,
    indexName: data?.searchProducts?.indexName ?? undefined,
    page: data?.searchProducts?.pagination?.page,
    limit: data?.searchProducts?.pagination?.limit,
  };

  return { products, searchMeta };
};
