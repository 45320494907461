import { cn } from "@/common/utils/css.utils";
import { VerifiedOutline as VerifiedOutlineIcon } from "@/assets/icons/24/VerifiedOutline";
import { AwardOutline as AwardOutlineIcon } from "@/assets/icons/24/AwardOutline";
import { Repeat as RepeatIcon } from "@/assets/icons/24/arrow/Repeat";
import { USPNote } from "@/components/Homepage/HomepageUSPNote";
import AnimatedBanner from "@/components/shared/AnimatedBanner";
import { Fragment } from "react";

const USPList = [
  <USPNote
    key="1"
    icon={
      <VerifiedOutlineIcon className="mr-2 inline-block h-5 text-brand-500" />
    }
    label={"homepage:homepage-brand-promise-usp-1"}
  />,
  <USPNote
    key="2"
    icon={<AwardOutlineIcon className="mr-2 inline-block h-5 text-brand-500" />}
    label={"homepage:homepage-brand-promise-usp-2"}
  />,
  <USPNote
    key="3"
    icon={<RepeatIcon className="mr-2 inline-block h-5 text-brand-500" />}
    label={"homepage:homepage-brand-promise-usp-3"}
  />,
];

type HomepageUSPBlockProps = {
  className?: string;
};

const HomepageUSPBlockDesktop = ({ className }: HomepageUSPBlockProps) => {
  return (
    <section
      className={cn(
        "-mt-4 flex flex-wrap items-center justify-center space-x-16 space-y-4 bg-flavour-200 p-4 [&>:first-child]:mt-4",
        className
      )}
    >
      {USPList.map(usp => (
        <Fragment key={usp.key}>{usp}</Fragment>
      ))}
    </section>
  );
};

const DURATION = 2500; // 2.5 seconds.

const HomepageUSPBlockMobile = ({ className }: HomepageUSPBlockProps) => {
  return (
    <section
      className={cn(
        "flex h-14 items-center justify-center overflow-hidden bg-flavour-200 px-4 text-center md:px-0",
        className
      )}
    >
      <AnimatedBanner
        enter="w-full transition duration-500"
        enterFrom="opacity-0 -translate-x-full"
        enterTo="opacity-100 translate-x-0"
        leave="w-full transition duration-500"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-full"
        panels={USPList}
        timeout={DURATION}
      />
    </section>
  );
};

export const HomepageUSPBlock = () => {
  return (
    <>
      <HomepageUSPBlockDesktop className="hidden md:flex" />
      <HomepageUSPBlockMobile className="md:hidden" />
    </>
  );
};
